import React from "react"
import Layout from "../components/layout"

export default function NotFound({ location }) {
  return (
    <Layout location={location}>
      <header>
        <h2 className="page-title">Ne pare rău, pagina nu poate fi găsită.</h2>
      </header>
    </Layout>
  )
}
